import styled from "styled-components";

import base from "@/themes/base/base";

export const Container = styled.div`
    display: flex;
    width: 100%;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    margin-left: 1.5rem;
`;

export const ChartWrapper = styled.div`
    display: flex;
    height: 20.1875rem;
    margin-top: 1.25rem;

    .recharts-polar-angle-axis-tick text {
        fill: ${base.colors.neutral.grey[300]};
        ${base.typography.baseRegular};
    }

    .recharts-label-list text {
        ${base.typography.extraSmallRegular};
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;
