import styled, { css } from "styled-components";

import base from "@/themes/base/base";

import { Container as SemiCircularGaugeContainer } from "../semi-circular-gauge/gauge-styles";

export const ScoringWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-left: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
`;

export const SubtitleAndTooltipWrapper = styled.div`
    display: flex;
    gap: 0.25rem;
    padding: 0.65625rem 0;
`;

export const ScoringAndCircuitWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const CircuitScoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
`;

export const ProgressBarAndSubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
`;

export const DividerWrapper = styled.div`
    display: flex;
    padding: 0 1.5rem;
`;

export const Divider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 0.0625rem;
    height: 8rem;
`;

export const LinearProgressAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    padding-top: 0.5rem;
`;

interface StylingProps {
    isIndividualCircuit?: boolean;
}

export const LinearProgressWrapper = styled.div<StylingProps>`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    ${({ isIndividualCircuit }) =>
        !isIndividualCircuit &&
        css`
            max-height: 11.875rem;
            overflow-y: scroll;
            padding-right: 0.375rem; //the space between the content & the scrollbar
        `}

    /* Scrollbar */
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
`;

export const CircuitProgressContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const LabelButton = styled.button`
    ${base.typography.smallRegular};

    font-family: "Roboto";
    color: ${base.colors.neutral.grey[300]};
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
        color: ${base.colors.neutral.grey[50]};
        text-decoration: underline;
    }
`;

export const ResultLabel = styled.span`
    ${base.typography.largeRegular};
`;

export const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
`;

interface ComponentsWrapperProps {
    scrollableContainerHeight: number;
}

export const ComponentsScrollableWrapper = styled.div<ComponentsWrapperProps>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: scroll;

    height: ${({ scrollableContainerHeight }) => `${scrollableContainerHeight}px`};

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera browsers */
    }
`;

export const SemiCircularGaugeAndCircuitScoringWrapper = styled.div`
    display: flex;
    width: 100%;

    ${SemiCircularGaugeContainer} {
        width: 50.46%;
    }
`;
