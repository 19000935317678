import styled, { css } from "styled-components";

import base from "@/themes/base/base";

export const DataContainer = styled.div<{ isPartOfSharedRow?: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
    position: relative;
    min-height: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "0" : "20rem")};
    height: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "15.1875rem" : "auto")};
`;

export const SectionOverlay = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    font-size: 1.5rem;
    font-weight: bold;
    border-right: 2px solid ${base.colors.primary.orange[500]};
`;

export const LeftSection = styled(SectionOverlay)`
    flex: 0 0 17.5%;
`;

export const CenterTopSection = styled(SectionOverlay)`
    height: 50%;
    border-bottom: 2px solid ${base.colors.primary.orange[500]};
`;

export const CenterBottomSection = styled(SectionOverlay)`
    height: 50%;
`;

export const RightSection = styled(SectionOverlay)`
    flex: 0 0 17.5%;
    border-right: none;
`;

export const EyeTrackingImage = styled.img`
    width: 100%;
    height: auto;
`;

export const CenterSection = styled.div`
    flex: 0 0 65%;
    height: 100%;
`;

export const SectionText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${base.colors.legacy.gray[50]};
`;

export const SectionPercentage = styled.div`
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 300;
    color: ${base.colors.legacy.gray[50]};
    text-align: center;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    border: 2px solid ${base.colors.primary.orange[500]};
`;

export const ColoredOverlay = styled(Overlay)`
    background-color: ${base.colors.primary.orange[500]};
    opacity: 0.25;
`;

const scoreContainer = css`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
`;

export const Container = styled.div<{ isPartOfSharedRow?: boolean }>`
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    width: 100%;
    overflow-x: hidden;
    height: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "21.1875rem" : "26.4375rem")};
    margin-left: ${({ isPartOfSharedRow }) => (isPartOfSharedRow ? "1.5rem" : "")};
`;

export const LandingScoreContainer = styled.div`
    ${scoreContainer}
    flex: 3.3;
    height: 100%;
`;
