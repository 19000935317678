import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useSessionDetailsContext } from "@/context/session-details/session-details";

import { SubTitle, Title } from "@/pages/session-detail/session-detail.styles";
import { TertiaryButton } from "@/pages/shared/shared.styles";

import { ChevronRightSquareCornersIcon24 } from "@/components/icons";
import { ProgressBar } from "@/components/ui/progress-bar/progress-bar";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { scrollToTop } from "@/utils/scroll-to-top";

import {
    ButtonsWrapper,
    CircuitScoreWrapper,
    Divider,
    DividerWrapper,
    LinearProgressAndButtonWrapper,
    LinearProgressWrapper,
    ProgressBarAndSubtitleWrapper,
    ScoringAndCircuitWrapper,
    ScoringWrapper,
    SubtitleAndTooltipWrapper,
} from "../../scoring.styles";
import type {
    CircuitParameter,
    HandleCircuitClickProps,
    LinearProgressBarDataSet,
    ProgressBarDataSet,
} from "../../scoring.types";

import LinearProgressCircuit from "../linear-progress-circuit/linear-progress-circuit";

interface ProgressBarAndCircuitScoringProps {
    isIndividualCircuit?: boolean;
    circuitScoreData: ProgressBarDataSet;
    linearProgressBarData?: LinearProgressBarDataSet[];
    circuitParameters?: CircuitParameter[];
    handleCircuitModalOpen: ({ circuit, isReportsButtonClicked }: HandleCircuitClickProps) => void;
}

const ProgressBarAndCircuitScoring = ({
    isIndividualCircuit,
    circuitScoreData,
    linearProgressBarData,
    circuitParameters,
    handleCircuitModalOpen,
}: ProgressBarAndCircuitScoringProps) => {
    const { selectedIndex, setSelectedFilterOption, setSelectedIndex } = useSessionDetailsContext();

    //scrolls to the specific parameter that was clicked, inside a circuit
    const handleClickOnCircuitParameter = (circuitIndex: number) => {
        const circuitTopStatsId = `circuit-top-stats-${circuitIndex}`;
        const circuitTopStatsElement = document.getElementById(circuitTopStatsId);

        if (circuitTopStatsElement) {
            circuitTopStatsElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    const handleReportsButtonClick = () => {
        if (linearProgressBarData) {
            handleCircuitModalOpen({
                circuit: linearProgressBarData[0],
                isReportsButtonClicked: true,
            });
        }
    };

    //scrolls to top when a new circuit is selected
    useEffect(() => {
        scrollToTop("components-scrollable-wrapper");
    }, [selectedIndex]);

    return (
        <ScoringWrapper>
            <Title>
                {circuitScoreData?.scoreTitle}
                <Tooltip formattedMessage="session-detail:circuit-scoring-tooltip" />
            </Title>

            <ScoringAndCircuitWrapper>
                <CircuitScoreWrapper>
                    <ProgressBarAndSubtitleWrapper>
                        <ProgressBar
                            width={174}
                            valueEnd={Math.round(circuitScoreData?.score)}
                            scoreFontSize="3rem"
                            totalScoreFontSize="1.5rem"
                        />

                        <SubtitleAndTooltipWrapper>
                            <SubTitle>{circuitScoreData?.legend}</SubTitle>
                            <Tooltip simpleText={circuitScoreData?.description} />
                        </SubtitleAndTooltipWrapper>
                    </ProgressBarAndSubtitleWrapper>
                </CircuitScoreWrapper>

                <DividerWrapper>
                    <Divider />
                </DividerWrapper>

                <LinearProgressAndButtonWrapper>
                    <LinearProgressWrapper isIndividualCircuit={isIndividualCircuit}>
                        {linearProgressBarData &&
                            linearProgressBarData.map((circuit: any, index: number) => {
                                return (
                                    <LinearProgressCircuit
                                        circuit={circuit}
                                        key={`${circuit.name}-${index}`}
                                        handleOnClick={() => {
                                            handleCircuitModalOpen({
                                                circuit,
                                                isReportsButtonClicked: false,
                                            });
                                            setSelectedFilterOption(circuit);
                                            setSelectedIndex(index);
                                        }}
                                    />
                                );
                            })}
                        {circuitParameters &&
                            circuitParameters.map((parameter: CircuitParameter, index: number) => {
                                return (
                                    <LinearProgressCircuit
                                        circuit={parameter}
                                        key={`${parameter.name}-${index}`}
                                        handleOnClick={() => handleClickOnCircuitParameter(index)}
                                    />
                                );
                            })}
                    </LinearProgressWrapper>
                    {!isIndividualCircuit && (
                        <ButtonsWrapper onClick={handleReportsButtonClick}>
                            <Tooltip simpleText={"tooltip text"} />
                            <TertiaryButton>
                                <FormattedMessage id="session-detail:circuit-scoring-button" />
                                <ChevronRightSquareCornersIcon24 />
                            </TertiaryButton>
                        </ButtonsWrapper>
                    )}
                </LinearProgressAndButtonWrapper>
            </ScoringAndCircuitWrapper>
        </ScoringWrapper>
    );
};

export default ProgressBarAndCircuitScoring;
