import Loader from "@/components/ui/loader";

import type { ContentLoaderProps } from "./content-loader.types";

import { ContentLoaderWrapper } from "./content-loader.styles";

const ContentLoader = ({ height, marginLeft, marginRight, noBorderRadius }: ContentLoaderProps) => {
    return (
        <ContentLoaderWrapper
            height={height}
            marginLeft={marginLeft}
            marginRight={marginRight}
            noBorderRadius={noBorderRadius}
        >
            <Loader />
        </ContentLoaderWrapper>
    );
};

export default ContentLoader;
