import React, { useState, useEffect, useLayoutEffect, useRef, useMemo, createRef } from "react";
import GridLayout from "react-grid-layout";
import { useQuery } from "react-query";

import { LoaderContainer } from "@/app.styles";

import { getLayoutGrid } from "@/api/settings";

import { useFeatureToggles } from "@/context/feature-toggles";

import Loader from "@/components/ui/loader";

import { Features } from "@/enums/features";

import useQueryParams from "@/utils/use-query-params";

import { GridLayoutItem, StyledGridLayoutItem } from "./styles";

type SessionGridProps = {
    getComponentById: (component: string) => JSX.Element | null;
};

export interface LayoutItem {
    i: string;
    x: number;
    w: number;
    h: number;
    component: string;
    yIndex: number;
    y?: number;
    marginBottom?: number;
}

const componentFeatureMap: any = {
    SessionDetailScore: Features.SessionDetailScore,
    SessionDetailActionsPerformed: Features.SessionDetailActionsPerformed,
    ScenarioOverviewButton: Features.SessionOverview,
};

export interface LayoutRow {
    cols: Array<LayoutColumn | LayoutRow>;
}

export interface LayoutColumn {
    component: string;
    colspan?: number;
    rowspan?: number;
    marginBottom?: 24;
}

const SessionGrid: React.FC<SessionGridProps> = ({ getComponentById }) => {
    const query = useQueryParams();
    const scenarioInstanceId: any = query.get("scenarioInstanceId");
    const simulationId: any = query.get("simulationInstanceId");

    const {
        isLoading,
        data: layout,
        isFetched,
    } = useQuery(
        [simulationId, scenarioInstanceId],
        () => getLayoutGrid({ simulationId, scenarioInstanceId }),
        {
            suspense: true, //has to be true in order for the components to render
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true,
        },
    );

    const { isFeatureActive } = useFeatureToggles();
    const [componentHeights, setComponentHeights] = useState<Record<string, number>>({});
    const [componentRefs, setComponentRefs] = useState<
        Record<string, React.RefObject<HTMLDivElement>>
    >({});

    const parentRef = useRef<HTMLDivElement>(null);
    const [parentWidth, setParentWidth] = useState(0);
    const [needsScroll, setNeedsScroll] = useState(false);

    useEffect(() => {
        const debounceResize = (fn: any, delay: any) => {
            let timer: any = null;

            return (...args: any[]) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    fn.apply(this, args);
                }, delay);
            };
        };

        Object.entries(componentRefs).forEach(([key, ref]) => {
            const observer = new ResizeObserver(
                debounceResize(() => {
                    if (ref.current) {
                        setComponentHeights((prevHeights) => ({
                            ...prevHeights,
                            [key]: ref.current?.offsetHeight ?? 0,
                        }));
                    }
                }, 100),
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        });
    }, [componentRefs]);

    useEffect(() => {
        const gridLayout = document.getElementsByClassName("layout");

        if (gridLayout.length > 0) {
            setNeedsScroll(gridLayout[0].clientHeight > window.innerHeight);
        }
    }, [layout, isFetched, componentHeights]);

    useLayoutEffect(() => {
        const updateWidth = () => {
            if (parentRef.current) {
                setParentWidth(parentRef.current.clientWidth);
            }
        };

        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => window.removeEventListener("resize", updateWidth);
    }, [needsScroll]);

    const calculateLayoutItems = (
        rows: LayoutRow[],
        isFeatureActive: any,
        startY = 0,
        startX = 0,
    ) => {
        let currentY = startY;
        let layoutItems: any = [];
        let maxColSpan = 0;

        rows.forEach((row: LayoutRow) => {
            let currentX = startX;
            let rowHasActiveFeatures = false;
            let maxRowSpan = 0;
            let rowColSpanSum = 0;

            row.cols.forEach((col) => {
                if ("cols" in col) {
                    const nestedItems = calculateLayoutItems(
                        [col],
                        isFeatureActive,
                        currentY,
                        currentX,
                    );

                    if (nestedItems.items.length > 0) {
                        rowHasActiveFeatures = true;
                        layoutItems = [...layoutItems, ...nestedItems.items];
                        currentX += nestedItems.maxX;
                        maxRowSpan = Math.max(maxRowSpan, nestedItems.maxY - currentY);
                        rowColSpanSum += nestedItems.maxColSpan; // Sum nested colspans
                    }
                } else {
                    const componentFeature = componentFeatureMap[col.component];
                    const isFeatureActiveForComponent =
                        !componentFeature || isFeatureActive(componentFeature);

                    if (isFeatureActiveForComponent) {
                        rowHasActiveFeatures = true;
                        const item = {
                            i: `${col.component}`,
                            x: currentX,
                            y: currentY,
                            w: col.colspan || 1,
                            h: (componentHeights[col.component] ?? 1) + (col.marginBottom || 0),
                            component: col.component,
                        };
                        layoutItems.push(item);
                        currentX += item.w;
                        maxRowSpan = Math.max(maxRowSpan, item.h);
                        rowColSpanSum += item.w;
                    }
                }
            });

            if (rowHasActiveFeatures) {
                currentY += maxRowSpan;
            }

            maxColSpan = Math.max(maxColSpan, rowColSpanSum); // Update maxColSpan if the current row's colspan sum is greater
        });

        return { items: layoutItems, maxY: currentY, maxX: startX, maxColSpan }; // Return maxColSpan as part of the result
    };

    const rowSize = 1;

    const { items, maxColSpan } = useMemo(() => {
        return calculateLayoutItems(layout || [], isFeatureActive);
    }, [layout, isFeatureActive, componentHeights]);

    const calculatedLayout = items;
    const columnCount = maxColSpan;

    useEffect(() => {
        const generateComponentRefs = (layoutItems: any[]) => {
            const refs: Record<string, React.RefObject<HTMLDivElement>> = {};
            const uniqueComponents = new Set(layoutItems.map((item) => item.i));

            uniqueComponents.forEach((component) => {
                refs[component] = createRef();
            });

            return refs;
        };

        if (layout) {
            const { items } = calculateLayoutItems(layout, isFeatureActive);
            setComponentRefs(generateComponentRefs(items));
        }
    }, [layout]);

    if (isLoading)
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );

    return (
        <div ref={parentRef}>
            {parentWidth > 0 && columnCount > 0 && (
                <GridLayout
                    className="layout"
                    layout={calculatedLayout}
                    cols={columnCount}
                    rowHeight={rowSize}
                    width={parentWidth}
                    containerPadding={[1, 0]}
                    margin={[0, 0]}
                    style={{ position: "relative" }}
                    isDraggable={false}
                    isResizable={false}
                >
                    {calculatedLayout.map((item: any) => (
                        <GridLayoutItem key={item.i}>
                            <StyledGridLayoutItem ref={componentRefs[item.i]}>
                                {getComponentById(item.component)}
                            </StyledGridLayoutItem>
                        </GridLayoutItem>
                    ))}
                </GridLayout>
            )}
        </div>
    );
};

export default SessionGrid;
