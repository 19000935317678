import styled, { css } from "styled-components";

import base from "@/themes/base/base";

const scoreContainer = css`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
`;

export const Section = {
    Container: styled.div`
        display: flex;
        width: 100%;
        gap: 1rem;
        margin-right: 1.5rem;
        ${scoreContainer}
    `,

    SubContainer: styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
    `,

    FiringScore: styled.div`
        ${scoreContainer}
        width: 100%;
    `,

    NoData: styled.div`
        ${scoreContainer}
        flex: 1;
        height: 25rem;
    `,

    NoDataContent: styled.div`
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
    `,

    ScoreDescriptionWrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    `,

    PhaseWrapper: styled.div`
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        box-sizing: border-box;
        padding: 0.38rem 0.37rem 0.37rem 0.37rem;
    `,

    ScoreWrapper: styled.div`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 4.25rem;
    `,

    Description: styled.span`
        display: flex;
        justify-content: center;
        gap: 0.25rem;
        color: ${base.colors.neutral.grey[300]};
        ${base.typography.baseRegular};
        text-align: center;
    `,
};
