import styled from "styled-components";

import base from "@/themes/base/base";

export const PerformanceScoreWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-left: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
`;

export const SubtitleAndTooltipWrapper = styled.div`
    display: flex;
    gap: 0.25rem;
    padding: 0.65625rem 0;
`;

export const LandingScoreAndScoreBreakdownWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

export const LandingScoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex: 1;
`;

export const ScoreBreakdownWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 2;
`;

export const ScoreBreakdownCard = styled.div`
    display: contents;
`;

export const ProgressBarAndSubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
`;

export const NoDataWrapper = styled.div`
    display: flex;
    height: 18.4375rem;
`;

export const NoDataContent = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
`;

export const LandingScoreDividerWrapper = styled.div`
    display: flex;
    padding: 0 1.5rem;
`;

export const BreakdownDividerWrapper = styled.div`
    display: flex;
    padding: 0 0.75rem;
`;

export const LandingScoreDivider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 0.0625rem;
    height: 8rem;
`;

export const BreakdownDivider = styled.div`
    background-color: ${base.colors.neutral.grey[900]};
    width: 0.0625rem;
    height: 4rem;
`;
