import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { getCustomerSettings } from "@/api/settings";
import { getWelcomeSurvey } from "@/api/user";

import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import Loader from "@/components/ui/loader";

import { Client } from "@/enums/clients";

import { Container, PageLayout, StyledHeatLogo, TextWrapper } from "../onboarding-styles";

import BabcockSurvey from "./client-specific-surveys/babcock/babcock-survey";
import MLRSSurvey from "./client-specific-surveys/mlrs/mlrs-survey";
import MVPSurvey from "./mvp-survey/mvp-survey";

import type { MVPFormInputs } from "./mvp-survey/mvp-survey.types";
import type { WelcomeSurveyData } from "./survey.types";

import { WelcomeDesc, WelcomeTitle } from "./survey.styles";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const OnboardingSurvey = () => {
    const history = useHistory();

    const [isMVP, setIsMVP] = useState(true);
    const [isClientSpecific, setIsClientSpecific] = useState(false);
    const [mvpSurveyValues, setMVPSurveyValues] = useState<MVPFormInputs>({
        firstName: "",
        lastName: "",
        dateOfBirth: undefined,
        gender: undefined,
    });

    const { isFetching: isLoading, data: clientSurveyData } = useQuery<WelcomeSurveyData>(
        ["getWelcomeSurvey"],
        () => getWelcomeSurvey(),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
        },
    );

    const { isLoading: isCustomerSettingLoading, data: customerSettingsData } = useQuery(
        ["getCustomerSettings"],
        getCustomerSettings,
        {
            suspense: false,
            refetchOnMount: false,
            useErrorBoundary: true,
        },
    );

    const client = useMemo(() => {
        if (!isCustomerSettingLoading && customerSettingsData) {
            return customerSettingsData.customerName;
        }

        return "";
    }, [isCustomerSettingLoading, customerSettingsData]);

    //client-specific questions exist in sections array
    const isClientSurvey = (clientSurveyData?.sections || []).length > 0;

    if (isLoading) return <Loader />;

    const handleOnContinue = () => {
        history.push(`/success`);
    };

    const renderClientQuestionnaire = (client: Client, clientSurveyData: WelcomeSurveyData) => {
        switch (client) {
            case Client.MLRS:
                return (
                    <MLRSSurvey
                        handleOnContinue={handleOnContinue}
                        clientSurveyData={clientSurveyData}
                        setIsMVP={setIsMVP}
                        setIsClientSpecific={setIsClientSpecific}
                        mvpSurveyValues={mvpSurveyValues}
                    />
                );
            case Client.BABCOCK:
                return (
                    <BabcockSurvey
                        handleOnContinue={handleOnContinue}
                        clientSurveyData={clientSurveyData}
                        setIsMVP={setIsMVP}
                        setIsClientSpecific={setIsClientSpecific}
                        mvpSurveyValues={mvpSurveyValues}
                    />
                );
        }
    };

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>
                <Container>
                    <StyledHeatLogo />

                    {isMVP && (
                        <TextWrapper>
                            <WelcomeTitle>
                                <FormattedMessage id={"survey:welcome-title"} />
                            </WelcomeTitle>
                            <WelcomeDesc>
                                <FormattedMessage
                                    id={"survey:welcome-desc"}
                                    values={{ lineBreak: <br /> }}
                                />
                            </WelcomeDesc>
                        </TextWrapper>
                    )}

                    {isMVP && clientSurveyData && (
                        <MVPSurvey
                            handleOnContinue={handleOnContinue}
                            clientSurveyData={clientSurveyData}
                            isClientSurvey={isClientSurvey}
                            setIsClientSpecific={setIsClientSpecific}
                            setIsMVP={setIsMVP}
                            setMVPSurveyValues={setMVPSurveyValues}
                            defaultValues={mvpSurveyValues}
                        />
                    )}

                    {isClientSpecific &&
                        clientSurveyData &&
                        renderClientQuestionnaire(client, clientSurveyData)}
                </Container>
            </PageLayout>
        </VRIntlProviderComponent>
    );
};

export default OnboardingSurvey;
