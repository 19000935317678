import { useState, useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import moment from "moment";

import { getSessions } from "@/api/dashboard";

import type { OverviewSession } from "../mini-sessions-list.types";

interface FiltersState {
    userId: string | null;
    from: string | null;
    to: string | null;
    pageNumber?: number | null;
    dateRange?: string | null;
}

export const useSessionsList = (initialUserId: string) => {
    const [sessionList, setSessionList] = useState<OverviewSession[] | null>(null);

    const today = new Date();
    const todayFormatted = moment(today, "MM/DD/YYYY").format("MM/DD/YYYY");
    const todayInTimeStamp = today.getTime();

    const lastThreeYears = new Date(today.setFullYear(today.getFullYear() - 3));
    const lastThreeYearsFormatted = moment(lastThreeYears, "MM/DD/YYYY").format("MM/DD/YYYY");
    const lastYearInTimeStamp = lastThreeYears.getTime();

    const filters: FiltersState = useMemo(
        () => ({
            userId: initialUserId,
            from: lastThreeYearsFormatted,
            to: todayFormatted,
            dateRange: null,
        }),
        [initialUserId, lastThreeYearsFormatted, todayFormatted],
    );

    const sessionsListUrl = useMemo(
        () =>
            [
                "/sessions?",
                initialUserId && `traineeId=${initialUserId}`,
                `from=${lastYearInTimeStamp}`,
                `to=${todayInTimeStamp}`,
            ]
                .filter(Boolean)
                .join("&"),
        [initialUserId, lastYearInTimeStamp, todayInTimeStamp],
    );

    const onSuccess = useCallback((newSessions: any) => {
        setSessionList(newSessions);
    }, []);

    const { isFetching, isError } = useQuery(
        ["sessionsList", filters],
        () => getSessions(filters),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            onSuccess,
        },
    );

    return { sessionList, isFetching, sessionsListUrl, isError };
};
