import styled from "styled-components";

import base from "@/themes/base/base";

import type { ContentLoaderProps } from "./content-loader.types";

export const ContentLoaderWrapper = styled.div<ContentLoaderProps>`
    display: flex;
    height: ${({ height }) => (height ? height : "10rem")};
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${base.colors.neutral.grey[800]};
    border-radius: ${({ noBorderRadius }) => (noBorderRadius ? "" : "0.5rem")};
    margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft}`};
    margin-right: ${({ marginRight }) => marginRight && `${marginRight}`};
`;
