export enum Pages {
    Sessions = "/sessions",
    SessionDetail = "/sessions/session-analysis",
    Overview = "/overview",
    Settings = "/settings",
    Promo = "/promo",
    Users = "/users",
    Reports = "/reports",
    Privacy = "/privacy-policy",
    Welcome = "/welcome",
    Success = "/success",

    //Un-used (we can remove these once deprecated pages are removed)
    Dashboard = "/dashboard",
    DashboardAllTrainees = "/dashboard/all-trainees",
    DashboardTrainee = "/dashboard/trainee/:userId",
    DashboardAirmanship = "/dashboard/airmanship",
    SessionsOverview = "/sessions-overview",
    SessionsList = "/sessions-list",
    Trainees = "/trainees/",
    TraineesWithTab = "/trainees/:tab",
    TraineesActive = "/trainees/active",
    TraineesPending = "/trainees/pending",
    AddTrainee = "/add-trainee",
    AddAndAssign = "/add-and-assign",
    Support = "/support",
    Authentication = "/auth",
}

export const HeadlessPages = [Pages.Promo, Pages.Privacy, Pages.Welcome, Pages.Success];
