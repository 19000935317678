import { useSessionDetailsContext } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import Tooltip from "@/components/ui/tooltip/tooltip";

import { Client } from "@/enums/clients";

import { Title } from "../../session-detail.styles";

import Gauge from "./gauge";
import {
    Container,
    GaugeAndLegendContainer,
    Legend,
    LegendIcon,
    LegendText,
    LegendWrapper,
} from "./gauge-styles";

interface LegendProps {
    min: number;
    max: number;
    color: string;
    text: string;
}

export const SemiCircularGauge = ({ ...remainingProps }: any) => {
    const { customChartsData, isFetchingCustomCharts, customerName } = useSessionDetailsContext();

    const cognitiveDataset = customChartsData?.find(
        (dataSet: any) => dataSet.chartType === "Gauge",
    );
    const cognitiveData = cognitiveDataset?.datasets[0];

    const legend = cognitiveDataset?.legend?.items.sort(
        (a: LegendProps, b: LegendProps) => a.max - b.max,
    );
    const min = legend?.[0].min;
    const max = legend?.[legend.length - 1].max;
    const colors = legend?.map((item: LegendProps) => item.color);
    const value = Math.round(cognitiveData?.Score);

    const formatLabel = () => {
        const valueLegend = legend.find((r: LegendProps) => value >= r.min && value <= r.max);
        const label = valueLegend ? valueLegend.text : "";
        const color = valueLegend ? valueLegend.color : "";

        return { label, color };
    };

    const containerAndContentLoaderHeight = () => {
        switch (customerName) {
            case Client.ODYSSEY_BAE_UKSOUTH:
                return "28.0625rem";
            default:
                return "21.1875rem";
        }
    };

    const height = containerAndContentLoaderHeight();

    if (isFetchingCustomCharts) return <ContentLoader height={height} />;

    return (
        <Container height={height} {...remainingProps}>
            <Title>
                {cognitiveDataset?.description}
                <Tooltip formattedMessage="session-detail:cognitive-gauge-tooltip" />
            </Title>

            {!cognitiveData ? (
                <EmptyState
                    title={"session-detail:gauge-empty-state-description"}
                    emptyStateType={EmptyStateType.Charts}
                    minHeight="15.1875rem"
                />
            ) : (
                <GaugeAndLegendContainer>
                    <Gauge
                        value={value}
                        formatLabel={formatLabel}
                        min={min}
                        max={max}
                        colors={colors}
                    />

                    <LegendWrapper>
                        {legend.map((item: LegendProps) => {
                            return (
                                <Legend key={item.max}>
                                    <LegendIcon color={item.color}></LegendIcon>
                                    <LegendText>{item.text}</LegendText>
                                </Legend>
                            );
                        })}
                    </LegendWrapper>
                </GaugeAndLegendContainer>
            )}
        </Container>
    );
};
