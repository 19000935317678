import styled from "styled-components";

export const Container = styled.div`
    background-color: #1a1a1a; /* Dark background */
    padding: 20px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
`;

export const StyledTable = styled.table`
    width: 100%;
    color: white;
    border-collapse: collapse;
    margin-top: 1.5rem;
`;

export const StyledHeader = styled.th`
    background-color: #666666;
    padding: 10px 15px;
    width: 12.5%;
    height: 2.5rem;
    font-weight: 400;
`;

export const StyledRow = styled.tr`
    width: 100%;
    background-color: #383838;

    &:nth-child(even) {
        background-color: #121212;
    }
`;

export const StyledCell = styled.td`
    text-align: center;
    width: 6.2%;
    height: 3.75rem;
`;

export const ScrollableTBody = styled.tbody`
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
`;

export const StyledHead = styled.thead`
    width: 100%;
    table-layout: fixed;
`;
