import LinearProgressBar from "@/components/ui/linear-progress-bar";

import { CircuitProgressContainer, LabelButton, ResultLabel } from "../../scoring.styles";

type LinearProgressProps = {
    circuit: CircuitProps;
    handleOnClick?: any;
};

type CircuitProps = {
    label: string;
    result: number;
};

const LinearProgressCircuit = ({ circuit, handleOnClick }: LinearProgressProps) => {
    return (
        <CircuitProgressContainer>
            <LabelButton onClick={handleOnClick}>{circuit.label}</LabelButton>

            <LinearProgressBar value={circuit.result} />

            <ResultLabel>{`${circuit.result}%`}</ResultLabel>
        </CircuitProgressContainer>
    );
};

export default LinearProgressCircuit;
