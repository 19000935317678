import { useCallback, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";

import { ChevronDownSquareCornersIcon20, ChevronDownSquareCornersIcon24 } from "@/components/icons";

import { DropdownFilterVariant } from "@/types/filters";

import { Container, List, PopUp, Toggle } from "../filters.styles";

import { Checkbox } from "./components/checkbox/checkbox";
import { CheckboxContainer } from "./components/checkbox/checkbox.styles";

import type { MultiSelectDropdownProps, Option } from "./multi-select.types";

import {
    ArrowIconContainer,
    SelectAllContainer,
    SelectedCountSpan,
    SeparatorLine,
    StyledItem,
    TextSpan,
    MultiSelectListContainer,
    TitleAndCountWrapper,
} from "./multi-select.styles";

export const MultiSelectDropdown = ({
    handleOnClick,
    onSelectionChange,
    options,
    title,
    icon,
    showSelectAll = false,
    variant = DropdownFilterVariant.Primary,
    popUpWidth,
    ...props
}: MultiSelectDropdownProps) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleList = useCallback(() => {
        setIsOpen((currentIsOpen) => !currentIsOpen);
    }, []);

    const handleOptionToggle = useCallback(
        (option: Option) => {
            setSelectedOptions((prevSelected) => {
                const isSelected = prevSelected.some(({ id }) => id === option.id);
                const newSelected = isSelected
                    ? prevSelected.filter(({ id }) => id !== option.id)
                    : [...prevSelected, option];

                onSelectionChange(newSelected);

                return newSelected;
            });
        },
        [onSelectionChange],
    );

    const handleSelectAll = useCallback(() => {
        const allSelected = options.length === selectedOptions.length;
        const newSelected = allSelected ? [] : options;

        setSelectedOptions(newSelected);
        onSelectionChange(newSelected);
    }, [options, selectedOptions, onSelectionChange]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: () => {
            setIsOpen(false);
            handleOnClick(null);
        },
    });

    return (
        <Container ref={clickOutsideRef} {...props}>
            <Toggle isOpen={isOpen} variant={variant} onClick={handleToggleList}>
                {icon}
                <TitleAndCountWrapper>
                    <span>{title}</span>
                    <SelectedCountSpan>{selectedOptions.length}</SelectedCountSpan>
                </TitleAndCountWrapper>
                <ArrowIconContainer isOpen={isOpen}>
                    {variant === DropdownFilterVariant.Primary ? (
                        <ChevronDownSquareCornersIcon24 />
                    ) : (
                        <ChevronDownSquareCornersIcon20 />
                    )}
                </ArrowIconContainer>
            </Toggle>

            {isOpen && (
                <PopUp popUpWidth={popUpWidth}>
                    <MultiSelectListContainer>
                        {showSelectAll && (
                            <SelectAllContainer
                                isChecked={options.length === selectedOptions.length}
                                onClick={handleSelectAll}
                            >
                                <SeparatorLine />
                                <CheckboxContainer>
                                    <Checkbox
                                        checked={options.length === selectedOptions.length}
                                        onChange={handleSelectAll}
                                    />
                                </CheckboxContainer>
                                <TextSpan
                                    className={
                                        options.length === selectedOptions.length ? "checked" : ""
                                    }
                                >
                                    <FormattedMessage id="filters:multi-select:select-all" />
                                </TextSpan>
                            </SelectAllContainer>
                        )}

                        <List>
                            {options.map((option) => {
                                return (
                                    <StyledItem
                                        key={option.id}
                                        variant={variant}
                                        isChecked={selectedOptions.some(
                                            ({ id }) => id === option.id,
                                        )}
                                        onClick={() => handleOptionToggle(option)}
                                    >
                                        <CheckboxContainer>
                                            <Checkbox
                                                checked={selectedOptions.some(
                                                    ({ id }) => id === option.id,
                                                )}
                                                onChange={() => handleOptionToggle(option)}
                                            />
                                        </CheckboxContainer>
                                        <TextSpan
                                            className={
                                                selectedOptions.some(({ id }) => id === option.id)
                                                    ? "checked"
                                                    : ""
                                            }
                                        >
                                            {option.label}
                                        </TextSpan>
                                    </StyledItem>
                                );
                            })}
                        </List>
                    </MultiSelectListContainer>
                </PopUp>
            )}
        </Container>
    );
};
