import { useRef, useState } from "react";

import { RefreshIcon24 } from "@/components/icons";
import { StyledRadioButton } from "@/components/ui/styled-radio";
import Tooltip from "@/components/ui/tooltip/tooltip";

import type { ChartState } from "@/types/timeline-chart";

import {
    Input,
    Label,
    RadioWrapper,
    Title,
    TitleAndRadioWrapper,
} from "../../session-detail.styles";

import Circuit3D from "./components/circuit/circuit";
import { Container, IconWrapper, TitleWrapper } from "./components/circuit/circuit-styles";

const FlyingCircuit = () => {
    const chartTypes = {
        Circuit: {
            label: "Circuit",
            color: "#F2F2F2",
        },
        ExpectedCircuit: {
            label: "Expected",
            color: "#EE7127",
        },
    };

    const threeCircuitRef = useRef<{ resetCamera: () => void } | null>(null);

    const [chartStateByType, setChartStateByType] = useState<ChartState>({
        Circuit: true,
        ExpectedCircuit: true,
    });

    const changeHandler = (chartType: string) => {
        setChartStateByType((prevState) => ({
            ...prevState,
            [chartType]: !prevState[chartType],
        }));
    };

    const refreshHandler = () => {
        if (threeCircuitRef.current) {
            threeCircuitRef.current.resetCamera();
        }
    };

    return (
        <Container>
            <TitleAndRadioWrapper>
                <TitleWrapper>
                    <Title>
                        Flight Path
                        <Tooltip simpleText="Flight Path" />
                    </Title>

                    <IconWrapper onClick={refreshHandler}>
                        <RefreshIcon24 />
                    </IconWrapper>
                </TitleWrapper>

                <RadioWrapper>
                    {Object.entries(chartTypes).map(([chartType, chartProps]) => {
                        const classNames = `
                ${chartStateByType[chartType] ? "" : "unchecked"}`.trim();

                        return (
                            <Label key={chartType} htmlFor={chartType} className={classNames}>
                                <Input
                                    type="checkbox"
                                    defaultChecked
                                    id={chartType}
                                    onChange={() => changeHandler(chartType)}
                                />
                                <StyledRadioButton
                                    selected={!!chartStateByType[chartType]}
                                    color={chartProps?.color}
                                ></StyledRadioButton>
                                {chartProps?.label}
                            </Label>
                        );
                    })}
                </RadioWrapper>
            </TitleAndRadioWrapper>

            <Circuit3D chartStateByType={chartStateByType} ref={threeCircuitRef} />
        </Container>
    );
};

export default FlyingCircuit;
