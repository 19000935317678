// TODO: PROVISORY CODE
import client from "@/client";

import welcomeMockData from "../../static/user-welcome-form.json";

const API_KEY = "adssfuhiwr3230SDFAsdf";

const OLD_API = "https://app-vrai-heat-dev.azurewebsites.net";

const USER_ID = "df2976a1-f0b5-492f-a57c-1845df53fe39";

import personalDetails from "./personal-details.json";

export const getUserAggregateTimes = async () => {
    const { data } = await client.get(
        `${OLD_API}/api/User/GetTraineeTimesAggregate?dateStart=2021-7-06T23%3A59%3A59&dateEnd=2021-7-06T23%3A59%3A59&userName=${USER_ID}`,
        {
            headers: { ApiKey: API_KEY },
        },
    );

    return data;
};

export const getUserTypeCount = async () => {
    const { data } = await client.get(`${OLD_API}/api/Instructor/GetUserTypeCount`, {
        headers: { ApiKey: API_KEY },
    });

    return data;
};

export const getUserListByType = async (key) => {
    const { data } = await client.get(
        `${OLD_API}/api/Instructor/GetProjectTrainneeAggregateData/${key}`,
        {
            headers: { ApiKey: API_KEY },
        },
    );

    return data;
};

export const getUserAggregateData = async () => {
    const { data } = await client.get(`${OLD_API}/api/Instructor/GetUserAggregateData/asd`, {
        headers: { ApiKey: API_KEY },
    });

    return data;
};

export const getUser = async () => await client.get(`v1/user/current`);

export const completeRegistration = async (fields) =>
    await client.put(`v1/user/current/completeregistration`, fields);

export const getWelcomeSurvey = async () => {
    const { data } = await client.get(`/v1/survey/welcome-survey`);

    return data;
};

export const getPersonalDetails = async () => {
    return personalDetails;
};

export const postWelcomeSurvey = async (payload) => {
    const { data } = await client.post(
        `IdasSignupSurveyUpload?code=msZBwWgPzP6Ifq6xgDF9Q7hVBxVAbs92qgw/IBHa2oT9WFROj4QODA==`,
        payload,
    );

    return data;
};

export const resendInvite = async (email) => {
    const { data } = await client.post(`/v1/resend-invite?email=${email}`);

    return data;
};

export const deactivateAccount = async (email, id) => {
    const { data } = await client.post(`/v1/deactivate-account?id=${id}`);

    return data;
};

export const requestPassCode = async (userId) => {
    const { data } = await client.get(`/v1/security?userId=${userId}`);

    return data;
};

export const getUserSiteMap = async () => {
    const { data } = await client.post(`/v1/sitemap`);

    return data.sitemap || data.sections;
};
