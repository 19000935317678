import styled from "styled-components";

import base from "@/themes/base/base";

export const Wrapper = styled.div<{
    bottomGap: boolean;
    borderTopRadius: boolean;
    borderBottomRadius: boolean;
    isInModal: boolean;
}>`
    padding: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    justify-content: center;
    margin-bottom: ${({ bottomGap }) => (bottomGap ? "1rem" : 0)};
    border-bottom-left-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
    border-bottom-right-radius: ${({ borderBottomRadius }) => (borderBottomRadius ? "0.5rem" : 0)};
    border-top-right-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
    border-top-left-radius: ${({ borderTopRadius }) => (borderTopRadius ? "0.5rem" : 0)};
    width: 100%;
    height: auto;
    padding-top: ${({ isInModal }) => (isInModal ? 0 : "1.5rem")};
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FullScreenDiv = styled.div<{ renderedInModal: boolean }>`
    height: ${({ renderedInModal }) => (renderedInModal ? "26.5rem" : "17.563rem")};
    margin-top: 1.5rem;
    border-radius: 0;
    overflow: hidden;
`;

export const ExpandIconContainer = styled.div`
    cursor: pointer;
`;

export const SpeedControlContainer = styled.div`
    display: flex;
    width: 100%;
    height: 2rem;
    justify-content: center;
    align-items: center;
    margin-top: 0.75rem;
`;

export const SpeedControlInnerContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 1.25rem;
`;

export const SpeedBlock = styled.div`
    flex-grow: 1;
    background-color: ${(props) => props.color};
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 2px;

    &:first-child {
        margin-right: 0;
    }
`;

export const SpeedBlockLabel = styled.div`
    flex-grow: 1;
    color: #999;
    margin: 0 0.5rem;
    font-size: 14px;
    align-items: center;
    display: flex;
`;
