import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

import { getSessions } from "@/api/dashboard";

import { useUser } from "@/context/user";

import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { CalendarIcon24 } from "@/components/icons";
import { VRIntlProviderComponent } from "@/components/providers/intl-provider";
import SessionItem from "@/components/session-item";
import Loader from "@/components/ui/loader";
import Page from "@/components/ui/page";

import { SortingFilterEnum } from "@/types/filters";
import type { FiltersState, GroupedSessions, Session } from "@/types/sessions";

import { DateFilterPresets } from "@/enums/dashboard";

import Filters from "./components/filters";

import { extractDate } from "./utils";

import {
    MainContainer,
    LoaderContainer,
    EmptyStateContainer,
    DateDisplayWrapperDiv,
    SessionItemRefContainer,
    GroupedSessionsContainer,
    DateDisplayFixedContainer,
} from "./sessions.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const SessionsPage = () => {
    const {
        state: { id: userId },
    } = useUser();
    const queryClient = useQueryClient();
    const [selectedUserId, setSelectedUserId] = useState(userId);
    const [filters, setFilters] = useState<FiltersState>({
        userId: selectedUserId,
        from: null,
        to: null,
        pageNumber: null,
        dateRange: null,
        orderBy: SortingFilterEnum.MostRecent,
    });
    const [sessionList, setSessionList] = useState<Session[] | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const location = useLocation();
    const observer = useRef<IntersectionObserver>();

    const { isFetching: isLoading, isError } = useQuery(
        ["sessionsList", filters],
        () => getSessions(filters),
        {
            suspense: false,
            refetchOnMount: false,
            useErrorBoundary: false,
            onSuccess: (newSessions) => {
                if (filters.from && filters.to) {
                    setSessionList((prevSessions) =>
                        prevSessions ? [...prevSessions, ...newSessions] : newSessions,
                    );
                    setHasMore(newSessions.length > 0);
                }
            },
        },
    );

    useEffect(() => {
        setSessionList(null);
        setHasMore(true);
    }, [filters.userId, filters.from, filters.to, filters.orderBy]);

    const lastSessionItemRef = useCallback(
        (node: any) => {
            if (isLoading || !hasMore) return;

            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && !isLoading && hasMore) {
                    setFilters((prevFilters) => ({
                        ...prevFilters,
                        pageNumber: prevFilters.pageNumber ? prevFilters.pageNumber + 1 : 2,
                    }));
                }
            });

            if (node) observer.current.observe(node);
        },
        [isLoading, hasMore],
    );

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const traineeId = queryParams.get("traineeId");
        const fromParam = queryParams.get("from");
        const toParam = queryParams.get("to");
        const dateRange = queryParams.get("dateRange");
        const orderBy = queryParams.get("orderBy");

        const initialFilters = {
            userId: traineeId,
            from: fromParam ? new Date(parseInt(fromParam)) : null,
            to: toParam ? new Date(parseInt(toParam)) : null,
            dateRange: dateRange,
            orderBy: orderBy,
        };

        if (!initialFilters.from && !initialFilters.to && !initialFilters.dateRange) {
            initialFilters.dateRange = DateFilterPresets.Last7Days;
        }

        setSelectedUserId(initialFilters.userId);

        setFilters((prevFilters) => ({
            ...prevFilters,
            userId: initialFilters.userId,
            ...(initialFilters.from && initialFilters.to
                ? {
                      from: initialFilters.from.toISOString(),
                      to: initialFilters.to.toISOString(),
                  }
                : {}),
            dateRange: initialFilters.dateRange,
            orderBy: initialFilters.orderBy,
        }));
    }, [location.search]);

    /* the purpose if this useEffect is to ensure that whenever the filters state changes,
  the data associated with the "sessionsList" query is invalidated and refetched.
  It triggers a change in Filters, making the 'All Trainees' option (inside TraineesFilter)
  work when there is no previous trainee selected */
    useEffect(() => {
        queryClient.invalidateQueries("sessionsList");
    }, [filters, queryClient]);

    const handleUserSelected = (userId: string | null) => {
        setSelectedUserId(userId);
        setSessionList(null);
        setFilters((prevFilters) => ({
            ...prevFilters,
            userId,
            pageNumber: 1,
        }));
    };

    const handleDateChange = (from: Date, to: Date) => {
        const formatDate = (date: Date) => {
            return new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }).format(date);
        };

        setSessionList(null);
        setFilters((prevFilters) => ({
            ...prevFilters,
            from: formatDate(from),
            to: formatDate(to),
            pageNumber: 1,
        }));
    };

    const handleSortingChange = (sortingOption: string | null) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                orderBy: sortingOption,
                pageNumber: 1,
            };
        });
    };

    useEffect(() => {
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);

    const groupedSessionList = useMemo(() => {
        return (sessionList || []).reduce<GroupedSessions>((acc, session) => {
            const date = extractDate(session.sessions[0].from);

            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push(session);

            return acc;
        }, {});
    }, [sessionList]);

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="sessions-list-page" fallback={null}>
            <Page
                title="Sessions"
                rightContent={
                    <Filters
                        onUserSelected={handleUserSelected}
                        onDateChange={handleDateChange}
                        initialUserId={selectedUserId}
                        initialDateRange={{
                            from: filters.from ? new Date(filters.from) : null,
                            to: filters.to ? new Date(filters.to) : null,
                        }}
                        dateRange={filters.dateRange}
                        onSortingChange={handleSortingChange}
                        sortingOption={filters.orderBy}
                    />
                }
            >
                <MainContainer hasSessions={!isError && (sessionList || []).length > 0}>
                    {Object.entries(groupedSessionList).map(([date, sessions]) => {
                        return (
                            <GroupedSessionsContainer key={date}>
                                <DateDisplayFixedContainer />
                                <DateDisplayWrapperDiv>
                                    <CalendarIcon24 fill="#F2F2F2" />
                                    {date}
                                </DateDisplayWrapperDiv>
                                {(sessions || []).map((session: Session, index: number) => (
                                    <React.Fragment key={session.id}>
                                        {index + 1 === sessions.length ? (
                                            <SessionItemRefContainer ref={lastSessionItemRef}>
                                                <SessionItem
                                                    session={session}
                                                    selectedUserId={selectedUserId}
                                                    index={index}
                                                />
                                            </SessionItemRefContainer>
                                        ) : (
                                            <SessionItem
                                                session={session}
                                                selectedUserId={selectedUserId}
                                                index={index}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </GroupedSessionsContainer>
                        );
                    })}
                    {(isLoading || (isLoading && sessionList && sessionList.length > 0)) && (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    )}

                    {!isLoading && sessionList?.length === 0 && (
                        <EmptyStateContainer>
                            <EmptyState
                                title="session-list:empty-state:title"
                                description="session-list:empty-state:description"
                                emptyStateType={EmptyStateType.Sessions}
                            />
                        </EmptyStateContainer>
                    )}

                    {!isLoading && isError && (
                        <EmptyStateContainer>
                            <EmptyState
                                title="session-list:error-state:title"
                                description="session-list:error-state:description"
                                emptyStateType={EmptyStateType.Error}
                            />
                        </EmptyStateContainer>
                    )}
                </MainContainer>
            </Page>
        </VRIntlProviderComponent>
    );
};

export default SessionsPage;
