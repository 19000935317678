import React from "react";

import { SubTitle, Title, Divider } from "../../session-detail.styles";

import { Section } from "./top-stats-styles";
import { secondsToMinutes } from "./utils";

export type TopStatProps = {
    description: string;
    value: value[];
    code: string;
    position: number;
};

type value = {
    title: string;
    result: number | string;
    unit: string;
};

export const CustomTopStat = ({ description, value }: TopStatProps) => {
    if (!value || value?.length === 0) return null;

    const getValue = (value: number | string, unit: string) => {
        if (typeof value === "string") {
            return value;
        }

        const numericValue = typeof value === "string" ? parseFloat(value) : value;

        if (!isNaN(numericValue)) {
            let updatedValue = `${Math.round(numericValue).toLocaleString()}${unit}`;

            if (unit.toLowerCase() === "s" && typeof secondsToMinutes === "function") {
                updatedValue = secondsToMinutes(numericValue);
            }

            if (numericValue < 1 && numericValue > 0) {
                updatedValue = `${numericValue.toFixed(1)}${unit}`;
            }

            return updatedValue;
        }
    };

    return (
        <Section.Container>
            <Title>{description}</Title>

            <Section.SubContainer>
                {value.map((stat: value, index: number) => {
                    return (
                        <React.Fragment key={`${stat.result}-${index}`}>
                            <Section.Wrapper>
                                <SubTitle>{stat.title}</SubTitle>
                                <Section.Value>{getValue(stat.result, stat.unit)}</Section.Value>
                            </Section.Wrapper>
                            {index < value.length - 1 && <Divider />}
                        </React.Fragment>
                    );
                })}
            </Section.SubContainer>
        </Section.Container>
    );
};
