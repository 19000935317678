import React, { useContext, useState, createContext } from "react";
import type { ReactNode } from "react";

import base from "@/themes/base/base";

import { SessionDetailsProvider } from "../session-details/session-details";

interface ModalContextType {
    isModalOpen: boolean;
    openModal: ({
        content,
        backgroundColor,
        borderRadius,
        padding,
        boxShadow,
    }: OpenModalProps) => void;
    closeModal: () => void;
    modalContent: ReactNode | null;
    width: string;
    height: string;
    setWidth: (width: string) => void;
    setHeight: (height: string) => void;
    minWidth: string;
    minHeight: string;
    setMinWidth: (width: string) => void;
    setMinHeight: (height: string) => void;
    backgroundColor: string | undefined;
    borderRadius: string | undefined;
    padding: string;
    boxShadow: string;
}

interface OpenModalProps {
    content: ReactNode;
    backgroundColor?: string;
    borderRadius?: string;
    padding?: string;
    boxShadow?: string;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [width, setWidth] = useState<string>("1032px");
    const [height, setHeight] = useState<string>("581px");
    const [minHeight, setMinHeight] = useState<string>("");
    const [minWidth, setMinWidth] = useState<string>("");
    const [backgroundColor, setBackgroundColor] = useState(`${base.colors.neutral.grey[800]}`);
    const [borderRadius, setBorderRadius] = useState("0.5rem");
    const [padding, setPadding] = useState<string>("1.5rem");
    const [boxShadow, setBoxShadow] = useState<string>("0px 4px 4px 0px rgba(0, 0, 0, 0.25)");

    const openModal = ({
        content,
        backgroundColor,
        borderRadius,
        padding,
        boxShadow,
    }: OpenModalProps) => {
        //modal's customized styles must be sent as props, or else they'll affect all the modals throughout the project
        setBackgroundColor(backgroundColor || base.colors.neutral.grey[800]);
        setBorderRadius(borderRadius || "0.5rem");
        setPadding(padding || "1.5rem");
        setBoxShadow(boxShadow || "0px 4px 4px 0px rgba(0, 0, 0, 0.25)");

        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                modalContent,
                width,
                height,
                setWidth,
                setHeight,
                minWidth,
                minHeight,
                setMinWidth,
                setMinHeight,
                backgroundColor,
                borderRadius,
                padding,
                boxShadow,
            }}
        >
            <SessionDetailsProvider>{children}</SessionDetailsProvider>
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalContextType => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("useModal hook must be used within a ModalProvider");
    }

    return context;
};
