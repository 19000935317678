import styled from "styled-components";

import { CustomCheckedIcon, StyledRadioGroup } from "@/pages/onboarding/survey/survey.styles";

import base from "@/themes/base/base";

export const GroupName = styled.span`
    ${base.typography.smallMedium};

    display: flex;
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
    color: ${({ theme }) => theme.colors.neutral.grey[300]};
`;

export const RoleContainer = styled.div``;

export const StatusContainer = styled.div``;

export const Divider = styled.div`
    background-color: #121212;
    height: 1px;
    margin: 0 0.75rem;
`;

export const FilterRadioButton = styled(StyledRadioGroup)`
    flex-direction: column;

    .MuiFormControlLabel-root {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 1.25rem 0.5rem 0.75rem;
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .MuiButtonBase-root {
            margin-left: 0.125rem;
            padding: 0 0.125rem 0 0;
        }
    }

    .radio-icon {
        width: 16px;
        height: 16px;
    }

    .MuiTypography-root {
        ${base.typography.smallRegular};
    }
`;

export const FilterCheckedIcon = styled(CustomCheckedIcon)`
    width: 16px;
    height: 16px;

    &:before {
        width: 16px;
        height: 16px;
    }
`;
